<template>
  <div class="app-container">
    <!-- scrollToTop -->
    <!-- ================ -->
    <!--    <div class="scrollToTop"><i class="icon-up-open-big" /></div>-->
    <el-backtop target="window" :bottom="100">
      <div
        style="{
        height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        color: #1989fa;
      }"
      >
        UP
      </div>
    </el-backtop>

    <!-- page wrapper start -->
    <!-- ================ -->
    <div class="page-wrapper">
      <!-- header-top start -->
      <!-- ================ -->
      <div class="header-top">
        <div class="container">
          <div class="row">
            <div class="col-xs-2 col-sm-6">

              <!-- header-top-first start -->
              <!-- ================ -->
              <div class="header-top-first clearfix">
                <ul class="social-links clearfix hidden-xs">
                  <li><a href="#"><i class="fa fa-mobile" /> {{ contact_no }}</a></li>
                  <li><a href="#"><i class="fa fa-envelope-o"> {{ email }}</i></a></li>
                </ul>
                <div class="social-links hidden-lg hidden-md hidden-sm">
                  <div class="btn-group dropdown">
                    <button type="button" class="btn dropdown-toggle" data-toggle="dropdown"><i class="fa fa-share-alt" /></button>
                    <ul class="dropdown-menu dropdown-animation">
                      <li><a href="#"><i class="fa fa-mobile" /> {{ contact_no }}</a></li>
                      <li><a href="mailto:info@csptech.net"><i class="fa fa-envelope-o"> {{ email }}</i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- header-top-first end -->

            </div>
            <div class="col-xs-10 col-sm-6">
              <!-- header-top-second start -->
              <!-- ================ -->
              <div id="header-top-second" class="clearfix">

                <!-- header top dropdowns start -->
                <!-- ================ -->
                <div class="header-top-dropdown">
                  <!--<div class="btn-group dropdown">
                                        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown"><i class="fa fa-search"></i> Search</button>
                                        <ul class="dropdown-menu dropdown-menu-right dropdown-animation">
                                            <li>
                                                <form role="search" class="search-box">
                                                    <div class="form-group has-feedback">
                                                        <input type="text" class="form-control" placeholder="Search">
                                                        <i class="fa fa-search form-control-feedback"></i>
                                                    </div>
                                                </form>
                                            </li>
                                        </ul>
                                    </div>-->
                  <div class="btn-group dropdown">
                    <template v-if="isLogin">
                      <button type="button" class="btn dropdown-toggle" data-toggle="dropdown"><i class="fa fa-user" /> {{ name }}</button>
                      <ul class="dropdown-menu dropdown-menu-right dropdown-animation">
                        <li>
                          <form class="login-form">
                            <button type="button" class="btn btn-block btn-group btn-sm" @click="dialog.isVisible = true">{{ $t('header.login_form.changePwd') }}</button>
                            <button type="button" class="btn btn-block btn-group btn-dark btn-sm" @click="handleLogout">{{ $t('header.login_form.logout') }}</button>
                          </form>
                        </li>
                      </ul>
                    </template>
                    <template v-else>
                      <button type="button" class="btn dropdown-toggle" data-toggle="dropdown"><i class="fa fa-user" /> {{ $t('header.login') }}</button>
                      <ul class="dropdown-menu dropdown-menu-right dropdown-animation">
                        <li>
                          <form class="login-form">
                            <div class="form-group has-feedback">
                              <label class="control-label">{{ $t('header.login_form.username') }}</label>
                              <input v-model="loginForm.username" type="text" class="form-control" placeholder="">
                              <i class="fa fa-user form-control-feedback" />
                            </div>
                            <div class="form-group has-feedback">
                              <label class="control-label">{{ $t('header.login_form.password') }}</label>
                              <input v-model="loginForm.password" type="password" class="form-control" placeholder="" @keyup.enter="handleLogin">
                              <i class="fa fa-lock form-control-feedback" />
                            </div>
                            <div style="text-align: right">
                              <el-button type="text" @click="dialog.isVisible = true">{{ $t('header.login_form.changePwd') }}</el-button>
                            </div>
                            <button type="button" class="btn btn-block btn-group btn-dark btn-sm" @click="handleLogin">{{ $t('header.login_form.login') }}</button>
                          </form>
                        </li>
                      </ul>
                    </template>
                  </div>
                  <div class="btn-group dropdown">
                    <button type="button" class="btn dropdown-toggle" data-toggle="dropdown"><i class="fa fa-globe" /> {{ lang }}</button>

                    <ul class="dropdown-menu dropdown-menu-right dropdown-animation">
                      <li><a href="javascript:void(0)" @click="changeLang('en')">English</a></li>
                      <li><a href="javascript:void(0)" @click="changeLang('zh-CN')">中文</a></li>
                    </ul>
                  </div>

                </div>
                <!--  header top dropdowns end -->

              </div>
              <!-- header-top-second end -->
              <!--                            <p>=={{$t('header.hello')}}</p>-->
            </div>
          </div>
        </div>
      </div>
      <!-- header-top end -->

      <!-- header start (remove fixed class from header in order to disable fixed navigation mode) -->
      <!-- ================ -->
      <header class="header fixed clearfix">
        <div class="container">
          <div class="row">
            <div class="col-md-3">

              <!-- header-left start -->
              <!-- ================ -->
              <div class="header-left clearfix">

                <!-- logo -->
                <div class="logo">
                  <a href="/home"><img id="logo" src="/images/logo.png" alt="SCP"></a>
                </div>

                <!-- name-and-slogan -->
                <div class="site-slogan">
                  {{ $t('header.slogan') }}
                </div>

              </div>
              <!-- header-left end -->

            </div>
            <div class="col-md-9">

              <!-- header-right start -->
              <!-- ================ -->
              <div class="header-right clearfix">

                <!-- main-navigation start -->
                <!-- ================ -->
                <div class="main-navigation animated">

                  <!-- navbar start -->
                  <!-- ================ -->
                  <nav class="navbar navbar-default" role="navigation">
                    <div class="container-fluid">

                      <!-- Toggle get grouped for better mobile display -->
                      <div class="navbar-header">
                        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-collapse-1">
                          <span class="sr-only">Toggle navigation</span>
                          <span class="icon-bar" />
                          <span class="icon-bar" />
                          <span class="icon-bar" />
                        </button>
                      </div>

                      <!-- Collect the nav links, forms, and other content for toggling -->
                      <div id="navbar-collapse-1" class="collapse navbar-collapse">
                        <ul class="nav navbar-nav navbar-right">
                          <li class="mega-menu">
                            <a href="/home">{{ $t('header.menu.home') }}</a>
                          </li>
                          <!-- mega-menu start -->
                          <li class="mega-menu">
                            <a href="/srrc">{{ $t('header.menu.srrc') }}</a>
                          </li>
                          <!-- mega-menu end -->
                          <li class="mega-menu">
                            <a href="/nal">{{ $t('header.menu.nal') }}</a>
                          </li>
                          <!-- mega-menu start -->
                          <li class="mega-menu">
                            <a href="/ccc">{{ $t('header.menu.ccc') }}</a>
                          </li>
                          <!-- mega-menu end -->
                          <!-- mega-menu start -->
                          <li class="mega-menu">
                            <a href="/nmpa">{{ $t('header.menu.nmpa') }}</a>
                          </li>
                          <!-- mega-menu end -->
                          <!-- mega-menu start -->
                          <li class="mega-menu">
                            <a href="/others">{{ $t('header.menu.others') }}</a>
                          </li>
                          <!-- mega-menu end -->
                          <li class="dropdown">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown">{{ $t('header.menu.news') }}</a>
                            <ul class="dropdown-menu">
                              <li><a href="/news/news-list?type=industry">{{ $t('header.menu.news_item.industry_info') }}</a></li>
                              <li><a href="/news/news-list?type=company">{{ $t('header.menu.news_item.company_news') }}</a></li>
                            </ul>
                          </li>
                          <li class="dropdown">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown">{{ $t('header.menu.service') }}</a>
                            <ul class="dropdown-menu">
                              <li><a href="/service/download">{{ $t('header.menu.service_item.download') }}</a></li>
                              <li v-permission="['ROLE_USER']"><a href="/service/project">{{ $t('header.menu.service_item.project') }}</a></li>
                              <li><a href="/service/certification">{{ $t('header.menu.service_item.certificate') }}</a></li>
                              <li><a href="/service/relation">{{ $t('header.menu.service_item.relation') }}</a></li>
                              <li><a href="/service/contact">{{ $t('header.menu.contact') }}</a></li>
                            </ul>
                          </li>
                          <!-- mega-menu start
                          <li class="mega-menu">
                            <a href="/contact">{{ $t('header.menu.contact') }}</a>
                          </li>
                          -->
                        </ul>
                      </div>

                    </div>
                  </nav>
                  <!-- navbar end -->

                </div>
                <!-- main-navigation end -->

              </div>
              <!-- header-right end -->

            </div>
          </div>
        </div>
      </header>
      <!-- header end -->

      <router-view :key="key" />

      <!-- footer start (Add "light" class to #footer in order to enable light footer) -->
      <!-- ================ -->
      <footer id="footer">

        <!-- .footer start -->
        <!-- ================ -->
        <div class="footer">
          <div class="container">
            <div class="row">
              <!--
              <div class="col-sm-6">
                <div class="footer-content">
                  <div class="logo-footer"><img id="logo-footer" src="images/logo_red_footer.png" alt=""></div>
                  <p>{{ $t('introduction.p1') }}</p>
                  <p>{{ $t('introduction.p2') }}</p>
                  <p>{{ $t('introduction.p3') }}</p>
                  <p>{{ $t('introduction.p4') }}</p>
                  <p>{{ $t('introduction.p5') }}</p>
                  <div class="row">
                    <div class="col-md-12">
                      <ul class="list-icons">
                        <li><i class="fa fa-map-marker pr-10" /> {{ $t('footer.address') }}</li>
                        <li><i class="fa fa-phone pr-10" /> {{ contact_no }}</li>
                        <li><i class="fa fa-envelope-o pr-10" /> {{ email }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="footer-content">
                  <a id="contact"></a>
                  <h2>{{ $t('footer.contact_us') }}</h2>
                  &lt;!&ndash;<div class="alert alert-success hidden" id="contactSuccess2">
                                        <strong>Success!</strong> Your message has been sent to us.
                                    </div>
                                    <div class="alert alert-error hidden" id="contactError2">
                                        <strong>Error!</strong> There was an error sending your message.
                                    </div>&ndash;&gt;
                  <form id="footer-form" role="form">
                    <div class="form-group has-feedback">
                      <label class="sr-only" for="name2">{{ $t('footer.name') }}</label>
                      <input id="name2" v-model="contact_form.name" type="text" class="form-control" :placeholder="$t('footer.name')" name="name2">
                      <i class="fa fa-user form-control-feedback" />
                    </div>
                    <div class="form-group has-feedback">
                      <label class="sr-only" for="company2">{{ $t('footer.company') }}</label>
                      <input id="company2" v-model="contact_form.company" type="text" class="form-control" :placeholder="$t('footer.company')" name="company2">
                      <i class="fa fa-user form-control-feedback" />
                    </div>
                    <div class="form-group has-feedback">
                      <label class="sr-only" for="mobile2">{{ $t('footer.mobile') }}</label>
                      <input id="mobile2" v-model="contact_form.contactNo" type="text" class="form-control" :placeholder="$t('footer.mobile')" name="mobile2">
                      <i class="fa fa-user form-control-feedback" />
                    </div>

                    <div class="form-group has-feedback">
                      <label class="sr-only" for="email2">{{ $t('footer.email') }}</label>
                      <input id="email2" v-model="contact_form.email" type="email" class="form-control" :placeholder="$t('footer.email')" name="email2">
                      <i class="fa fa-envelope form-control-feedback" />
                    </div>
                    <div class="form-group has-feedback">
                      <label class="sr-only" for="message2">{{ $t('footer.message') }}</label>
                      <textarea id="message2" v-model="contact_form.content" class="form-control" rows="4" :placeholder="$t('footer.message')" name="message2" />
                      <i class="fa fa-pencil form-control-feedback" />
                    </div>
                    <input type="button" :value="$t('footer.send')" class="btn btn-default" @click="submitContact">
                  </form>
                </div>
              </div>
              -->
              <nav class="navbar navbar-default" role="navigation">
                <!-- Toggle get grouped for better mobile display -->
                <div class="navbar-header">
                  <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-collapse-2">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar" />
                    <span class="icon-bar" />
                    <span class="icon-bar" />
                  </button>
                </div>
                <div id="navbar-collapse-2" class="collapse navbar-collapse">
                  <ul class="nav navbar-nav">
                    <li><a target="_blank" :href="$t('footer.relation.link1.url')">{{ $t('footer.relation.link1.label') }}</a></li>
                    <li><a target="_blank" :href="$t('footer.relation.link2.url')">{{ $t('footer.relation.link2.label') }}</a></li>
                    <li><a target="_blank" :href="$t('footer.relation.link3.url')">{{ $t('footer.relation.link3.label') }}</a></li>
                    <li><a target="_blank" :href="$t('footer.relation.link4.url')">{{ $t('footer.relation.link4.label') }}</a></li>
                    <li><a target="_blank" :href="$t('footer.relation.link5.url')">{{ $t('footer.relation.link5.label') }}</a></li>
                    <li><a target="_blank" :href="$t('footer.relation.link6.url')">{{ $t('footer.relation.link6.label') }}</a></li>
                    <li><a target="_blank" :href="$t('footer.relation.link7.url')">{{ $t('footer.relation.link7.label') }}</a></li>
                    <li><a target="_blank" :href="$t('footer.relation.link8.url')">{{ $t('footer.relation.link8.label') }}</a></li>
                    <li><a target="_blank" :href="$t('footer.relation.link9.url')">{{ $t('footer.relation.link9.label') }}</a></li>
                    <li><a target="_blank" :href="$t('footer.relation.link10.url')">{{ $t('footer.relation.link10.label') }}</a></li>
                    <li><a target="_blank" :href="$t('footer.relation.link11.url')">{{ $t('footer.relation.link11.label') }}</a></li>
                    <li><a target="_blank" :href="$t('footer.relation.link12.url')">{{ $t('footer.relation.link12.label') }}</a></li>
                    <li><a target="_blank" :href="$t('footer.relation.link13.url')">{{ $t('footer.relation.link13.label') }}</a></li>
                    <li><a target="_blank" :href="$t('footer.relation.link14.url')">{{ $t('footer.relation.link14.label') }}</a></li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <!-- .footer end -->

        <!-- .subfooter start -->
        <!-- ================ -->
        <div class="subfooter">
          <div class="container">
            <div class="row">
              <div class="align-content-md-center">
                <ul class="list-icons" style="text-align: left">
                  <li><i class="fa fa-map-marker pr-10" /> {{ $t('footer.address') }}</li>
                  <li><i class="fa fa-phone pr-10" /> {{ contact_no }}</li>
                  <li><i class="fa fa-envelope-o pr-10" /> {{ email }}</li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="align-content-md-center">
                <p>Copyright &copy; 北京科思普科技有限公司 All rights reserved.  </p>
              </div>
              <div class="credits">
                <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备09090836号</a>
              </div>
              <div class="col-md-7">
                <!--<nav class="navbar navbar-default" role="navigation">
                  &lt;!&ndash; Toggle get grouped for better mobile display &ndash;&gt;
                  <div class="navbar-header">
                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-collapse-2">
                      <span class="sr-only">Toggle navigation</span>
                      <span class="icon-bar" />
                      <span class="icon-bar" />
                      <span class="icon-bar" />
                    </button>
                  </div>
                  <div id="navbar-collapse-2" class="collapse navbar-collapse">
                    <ul class="nav navbar-nav">
                      <li><a target="_blank" :href="$t('footer.relation.link1.url')">{{ $t('footer.relation.link1.label') }}</a></li>
                      <li><a target="_blank" :href="$t('footer.relation.link2.url')">{{ $t('footer.relation.link2.label') }}</a></li>
                      <li><a target="_blank" :href="$t('footer.relation.link3.url')">{{ $t('footer.relation.link3.label') }}</a></li>
                      <li><a target="_blank" :href="$t('footer.relation.link4.url')">{{ $t('footer.relation.link4.label') }}</a></li>
                      <li><a target="_blank" :href="$t('footer.relation.link5.url')">{{ $t('footer.relation.link5.label') }}</a></li>
                      <li><a target="_blank" :href="$t('footer.relation.link6.url')">{{ $t('footer.relation.link6.label') }}</a></li>
                      <li><a target="_blank" :href="$t('footer.relation.link7.url')">{{ $t('footer.relation.link7.label') }}</a></li>
                      <li><a target="_blank" :href="$t('footer.relation.link8.url')">{{ $t('footer.relation.link8.label') }}</a></li>
                      <li><a target="_blank" :href="$t('footer.relation.link9.url')">{{ $t('footer.relation.link9.label') }}</a></li>
                      <li><a target="_blank" :href="$t('footer.relation.link10.url')">{{ $t('footer.relation.link10.label') }}</a></li>
                      <li><a target="_blank" :href="$t('footer.relation.link11.url')">{{ $t('footer.relation.link11.label') }}</a></li>
                      <li><a target="_blank" :href="$t('footer.relation.link12.url')">{{ $t('footer.relation.link12.label') }}</a></li>
                      <li><a target="_blank" :href="$t('footer.relation.link13.url')">{{ $t('footer.relation.link13.label') }}</a></li>
                      <li><a target="_blank" :href="$t('footer.relation.link14.url')">{{ $t('footer.relation.link14.label') }}</a></li>
                    </ul>
                  </div>
                </nav>-->
              </div>
            </div>
          </div>
        </div>
        <!-- .subfooter end -->

      </footer>
      <!-- footer end -->
    </div>
    <!-- page-wrapper end -->
    <change-pwd-dialog
      ref="dialog"
      :visible="dialog.isVisible"
      @close="handleDialogClose"
      @success="handleDialogSuccess"
    />
  </div>
</template>

<script>
// import $ from 'jquery'
import ChangePwdDialog from './component/ChangePwdDialog'
import { mapGetters, mapActions } from 'vuex'
import waves from '@/directive/waves' // waves directive
import permission from '@/directive/permission/index.js' // 权限判断指令
export default {
  name: 'Index',
  directives: { waves, permission },
  components: { ChangePwdDialog },
  data() {
    return {
      loading: false,
      email: 'info@csptech.net',
      contact_no: '010-88820266',
      dialog: {
        isVisible: false
      },
      contact_form: {
        name: '',
        contactNo: '',
        email: '',
        company: '',
        content: ''
      },
      isLogin: false,
      loginForm: {
        username: '',
        password: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'locale',
      'lang',
      'name',
      'sidebar',
      'avatar',
      'device',
      'accessToken'
    ]),
    key() {
      return this.$route.path
    }
  },
  watch: {
    accessToken() {
      // eslint-disable-next-line no-console
      // console.log(val)
      // this.isLogin = (val !== '')
      this.$router.go(0)
    }
  },
  mounted() {
    this.$i18n.locale = this.locale
    this.isLogin = (this.accessToken !== '')
  },
  methods: {
    ...mapActions('i18n', ['setLocale']),
    changeLang(locale) {
      this.$i18n.locale = locale
      this.setLocale(locale)
    },
    handleLogin() {
      this.loading = true
      this.$store.dispatch('user/login', this.loginForm)
        .then(() => {
          // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
          this.loading = false
          // this.$store.dispatch('user/getInfo')
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleLogout() {
      this.$store.dispatch('user/logout')
    },
    handleDialogClose() {
      this.dialog.isVisible = false
    },
    handleDialogSuccess() {
      this.dialog.isVisible = false
    }
  }
}
</script>

<style scoped lang="scss">
  .footer-content {
    p {
      text-indent: 2em;
      margin-bottom: 0px;
    }
  }
</style>
